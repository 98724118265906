@import './const';
@import './colors';
@import './fonts';

@import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/react/css/normalize.css';
@import '@ionic/react/css/structure.css';
@import '@ionic/react/css/typography.css';

//@import 'swiper/swiper.css';
//@import 'swiper/css/navigation';
//@import 'swiper/css/pagination';
//@import 'swiper/css/scrollbar';


html, body {
  overscroll-behavior: none;
}

scroll-shadow {
  display: inline-block;
  --scroll-shadow-size: 14;
  --scroll-shadow-top: radial-gradient(farthest-side at 50% 0%, #0003, #0000);
  --scroll-shadow-bottom: radial-gradient(farthest-side at 50% 100%, #0003, #0000);
  --scroll-shadow-left: radial-gradient(farthest-side at 0%, #0003, #0000);
  --scroll-shadow-right: radial-gradient(farthest-side at 100%, #0003, #0000);
}

.ion-page {
  background: #f4f4f4;
}

:root {
  --swiper-pagination-color: #9659e1;
}