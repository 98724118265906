@import '../../scss/colors';
@import '../../scss/const';

.EmptyState {
  font-size: 14px;
  text-align: center;
  color: $gray;

  h2 {
    font-family: $font-martin;
    text-transform: uppercase;
    font-size: 32px;
    line-height: .9;
    color: $gray;
  }

  p {
    width: 70%;
    margin: auto;
  }

  .EmptyState_Icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: 80px;
      height: 80px;
      color: $gray-1;
      margin: auto;
      display: block;
      z-index: 1;
    }

    .EmptyState_Background {
      svg {
        height: 60px;
        width: inherit;
        fill: #f0eef2;
      }

      position: absolute;
      z-index: 0;
    }
  }

  &.hasAction {
    h2 {
      color: $primary;
    }

    .EmptyState_Icon svg {
      color: $primary;
    }

    .EmptyState_Background svg {
      fill: $primary-lighter;
    }

    .EmptyState_Action {
      margin-top: 10px;
    }
  }
}