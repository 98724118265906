@import '../../scss/colors';
@import '../../scss/const';

.FullScreenLoader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    box-sizing: content-box;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 -1px 12px $shadow-light, 0 1px 4px 0 rgba(0, 0, 0, 0);
    width: 60px;
    height: 60px;
  }

  svg {
    width: 60px;
  }
}