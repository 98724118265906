@import '../../scss/colors';
@import '../../scss/const';

.BottomNavigation {
  background: white;
  box-shadow: 0 -1px 12px $shadow-light, 0 1px 4px 0 rgba(0, 0, 0, 0);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(60px + env(safe-area-inset-bottom));
  display: grid;
  align-content: center;
  border-radius: 1.25rem 1.25rem 0 0;
  transition: .4s;
  text-align: center;
  z-index: 1;
  padding-bottom: env(safe-area-inset-bottom);

  ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: 0 10px;
    padding: 0;

    li {
      flex: 1;
    }
  }

  a {
    color: $dark-gray;
    text-decoration: none;
    display: block;
    font-family: $font-martin;
    text-transform: uppercase;

    &.active {
      color: $primary;
    }

    span {
      display: block;
      line-height: 1.2;
    }

    span.NavTitle {
      font-size: 16px;
      line-height: 1;
    }
  }
}