@import '../../scss/colors';
@import '../../scss/const';

.PageListGroup {
  background: white;
}

.PageListItem {
  .material-symbols-outlined {
    font-variation-settings:
            'FILL' 0,
            'wght' 300,
            'GRAD' 0,
            'opsz' 24
  }

  .PageListItem_Icon {
    width: 38px;

    svg {
      width: 22.5px;
      height: 22.5px;
    }
  }

  &.PageListItemHeader {
    color: $dark-gray;
    text-decoration: none;
    display: block;
    font-family: $font-martin;
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 5px;
  }
}
