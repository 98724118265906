@import '../../scss/colors';
@import '../../scss/const';

.LoginForm {
  h2 {
    font-family: $font-martin;
    text-transform: uppercase;
    font-size: 26px;
    line-height: .9;
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
  }
}