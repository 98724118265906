@import '../../scss/colors';
@import '../../scss/const';

.ErrorPage {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  main {
    width: 100%;
  }

  h2 {
    font-family: $font-martin;
    text-transform: uppercase;
    font-size: 48px;
    line-height: .9;
    margin-bottom: 0;
    color: $primary;
  }

  p {
    margin-top: 10px;
  }

  .EmptyStateIcon svg {
    width: 80%;
    height: auto;
    margin: auto;
    display: block;
  }

  .Action {
    margin-top: 30px;
  }
}