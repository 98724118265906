.InstallPrompt {
  svg {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }

  #modal-title img {
    border-radius: 4px;
  }

  h2 {
    font-weight: 400;
    font-size: 26px;
    text-transform: uppercase;
    letter-spacing: normal;
    line-height: .9;
  }
}