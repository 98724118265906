@import '../../scss/colors';
@import '../../scss/const';

.TicketListItem, .TicketCard {
  width: 100%;
}

.TicketCard {
  .TicketCard_Name {
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .TicketCard_Attendee {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .TicketCard_Number {
    display: inline-block;
    color: white;
    padding: 0 5px;
    border-radius: 2px;
    background-color: $primary;
    font-size: 12px;
    margin-bottom: 5px;
  }

  .TicketCard_QR {
    margin: 20px 0 10px 0;

      .TicketCard_QR_Code {
        max-height: 200px;
        overflow: hidden;
        aspect-ratio: 1 / 1;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid $gray-2;
        margin: auto;

        svg {
          width: 100%;
          height: 100%;
        }
    }
  }

  margin-top: 6px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    height: 6px;
    width: 95%;
    margin-left: 2.5%
  }

  &:before {
    top: -5px;
    background: radial-gradient(circle, transparent, transparent 50%, #FBFBFB 50%, #FBFBFB 100% ) -7px -8px / 16px 16px repeat-x,
  }

  &:after {
    bottom: -5px;
    background: radial-gradient(circle, transparent, transparent 50%, #FBFBFB 50%, #FBFBFB 100% ) -7px -2px / 16px 16px repeat-x,
  }
}