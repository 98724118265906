@import '../../scss/colors';
@import '../../scss/const';

.DateNavigation {
  &.TopDateNavigation {
    background: $primary;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: env(safe-area-inset-top);
    height: calc(75px + env(safe-area-inset-top));
    box-shadow: 0 1px 12px $shadow-light, 0 1px 4px 0 rgba(0, 0, 0, 0);
    border-radius: 0 0 1.25rem 1.25rem;
  }

  &.InlineDateNavigation {
    margin: 20px 0;
    border-bottom: 1px solid rgba(99, 107, 116, 0.2);

    ul {
      margin: 0;
      gap: 10px;
    }

    button {
      background: none;
      position: relative;

      &:after {
        content: "";
        display: block;
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0;
        border-radius: 2px 2px 0 0;
      }

      &.active {
        background: none;
        color: $primary;

        &:after {
          background: $primary;
        }
      }
    }
  }

  width: 100%;
  display: grid;
  align-content: center;
  transition: .4s;
  text-align: center;
  z-index: 2;

  ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: 0 10px;
    padding: 0;

    li {
      flex: 1;
    }
  }

  a, button {
    color: $dark-gray;
    text-decoration: none;
    display: block;
    border-radius: 10px;
    padding: 5px 0;
    width: 100%;

    &.active {
      color: $primary;
      background-color: rgb(255 255 255 / 85%);
    }

    span {
      display: block;
      line-height: 1.2;
    }

    span.NavTitle {
      font-size: 13px;
    }
  }

  .DateNavigation_Date {
    opacity: .5;
    font-size: 12px;
  }

  .DateNavigation_Day {
    text-transform: uppercase;
    font-family: $font-martin;
    font-size: 24px;
  }
}

main.hasDateNavigation {
  margin-top: calc(75px + env(safe-area-inset-top));
}