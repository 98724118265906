@import '../../../scss/colors';
@import '../../../scss/const';

.Hosts {
  background: white;
}

.WebsiteScheduleItem {
  .Close {
    z-index: 10;
    svg {
      color: white!important;
    }

    &:hover {
      svg {
        color: $dark-gray!important;
      }
    }
  }

  .HtmlContent {
    padding: 20px;
    width: 500px;
    max-width: 100%;
  }

  .PageHeader {
    border-radius: 0;
  }
}

.SchedulePage {
  .isToday {
    .SchedulePage_Past {
      opacity: .6;
    }
  }

  .SchedulePage_Group {
    transition: opacity 0.3s, visibility 0.3s;

    &:hover {
      opacity: 1 !important;
    }
  }
}