@import '../../scss/colors';
@import '../../scss/const';

.ScheduleItemCard {
  width: 100%;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  gap: 0!important;

  .ScheduleItemCard_Tag {
    font-size: 9px;
    display: inline-block;
    margin-right: auto;
    padding: 0 5px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 2px;
    margin-bottom: 5px;
  }

  .ScheduleItemCard_Name {
    font-family: $font-martin;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 10px;
  }

  .ScheduleItemCard_Time {
    color: $gray;
  }

  .ScheduleItemCard_Hosts {
    margin-top: 10px;
  }

  .ScheduleItemCard_Details {
    color: $gray;
    font-size: 13px;

    & div {
      display: inline-block;

      &:after {
        content: '•';
        margin: 0 4px;
      }

      &:last-child:after {
        content: none;
      }
    }
  }
}