@import '../../../scss/colors';
@import '../../../scss/const';

.InfoDisplay {
  background-image: url(https://site.assets.soulsurvivor.nl/uploads/2024/04/21120442/app-header-1.jpg);
  height: 100vh;
  background-size: cover;
  background-position: center;

  .Logo {
    width: 120px;
    position: absolute;
    top: 60px;
    left: 60px;

    svg path {
      fill: $primary-lighter;
    }
  }

  .CTA {
    background: white;
    position: absolute;
    bottom: 60px;
    left: 60px;
    padding: 30px;
    display: flex;
    border-radius: 8px;
    box-shadow: 0 1px 12px $shadow-light, 0 1px 4px 0 rgba(0, 0, 0, 0);
    align-items: center;

    .Text {
      width: 470px;
      font-size: 24px;

      strong {
        font-size: 36px;
      }
    }

    .QR {
      margin-left: 20px;

      svg {
        height: 170px;
        width: 170px;
      }
    }
  }

  .Program {
    width: 600px;
    position: absolute;
    right: 60px;
    top: 60px;
    background: white;
    padding: 30px;
    height: calc(100% - 120px);
    border-radius: 8px;
    box-shadow: 0 1px 12px $shadow-light, 0 1px 4px 0 rgba(0, 0, 0, 0);
    overflow: hidden;

    .UpcomingItemGroup {
      margin: 0;

      .UpcomingItemGroup_Title {
        color: #4A250A;
        font-size: 50px;
      }
    }

    .ScheduleItemCard {
      padding: 0 0 30px 0;
      margin-bottom: 30px;
      box-shadow: none;
      border-bottom: 1px solid rgba(99, 107, 116, 0.2);
      border-radius: 0;

      &:last-child {
        border-bottom: none;
      }
    }

    .ScheduleItemCard .ScheduleItemCard_Name {
      font-size: 42px;
    }

    .ScheduleItemCard_Details {
      font-size: 24px;
    }

    .ScheduleItemCard_HostNames {
      font-size: 20px;
    }

    .ScheduleItemCard_HostsAvatars {
      .MuiAvatar-sizeMd {
        height: 50px;
        width: 50px;
      }
    }
  }
}