main {
    padding: 20px;
    margin-bottom: calc(60px + env(safe-area-inset-bottom));

    &.WithFooter {
        margin-bottom: 0;
    }
}

.SlideCard {
    padding: 0 20px;
}
.PageWrapper.IsEmbedded {
    padding-top: 60px;

    .ScheduleSwiper {
        display: flex;
        flex-direction: column-reverse;

        .DateNavigation {
            position: fixed;
            top: 0;
            left: 0;
            margin-top: 0;
            height: 40px;
            background: #f4f4f4;
        }

        .SchedulePage {
            padding-top: 0;
            margin-bottom: 0;
        }
    }
}
.PageWrapper.FullHeight {
    height: 100vh;
    padding: 20px;

    .rbc-timeslot-group {
        min-height: 75px;
    }
}

.SchedulePage.hasDateNavigation {
    height: calc(100vh - 75px - 60px - env(safe-area-inset-top));
    overflow: auto;
}

main.Pages {
    margin-top: env(safe-area-inset-top);
}