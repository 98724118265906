@import '../../scss/colors';
@import '../../scss/const';

.TicketGroup {
  margin: 20px 0;

  .TicketList {
    padding: 0;
  }

  .TicketListItem {
    padding: 0;
  }

  .TicketGroup_Title {
    font-family: $font-martin;
    text-transform: uppercase;
    font-size: 28px;
    line-height: 1;
    margin: 0 0 8px 0;
    font-weight: 400;
  }

  .swiper {
    overflow-y: initial;
    .swiper-wrapper {
      margin-bottom: 30px;
    }
  }
}