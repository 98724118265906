@import '../../scss/colors';
@import '../../scss/const';

.PageHeader {
  background-size: cover;
  border-radius: 0 0 1.25rem 1.25rem;
  overflow: hidden;
  flex-direction: column;
  background-position: center;
  //background-color: white;
  //background-image: url("https://site.assets.soulsurvivor.nl/uploads/2023/12/14131413/WEBSITE_HEADER.jpg");

  .PageHeader_Bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;

    .PageHeader_Bar_Overlay {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: calc(65px + env(safe-area-inset-top));
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    }

    .PageHeader_Buttons {
      margin-top: env(safe-area-inset-top);
      margin-bottom: auto;
      margin-right: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      a, button {
        background: none;
        padding: 20px;
        color: white;
        display: block;
      }

      .PageHeader_Refresh {
        margin-right: 0;
      }
    }

    .PageHeader_Back {
    }

    .PageHeader_Refresh {
      margin-top: env(safe-area-inset-top);
      margin-bottom: auto;
      margin-right: auto;

      button:disabled {
        svg {
          opacity: .5;
          -webkit-animation: spin 1s linear infinite;
          -moz-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
        }
      }
    }
  }
}

.PageHeader .PageHeader_Content {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 20px;
  display: flex;
  flex: 1;
  margin-top: auto;
  margin-right: auto;
  z-index: 1;
}

.PageHeader .PageHeader_Overlay {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0) 200px), linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0) 300px);
}

.PageHeader_Title {
  font-family: $font-martin;
  text-transform: uppercase;
  font-size: 48px;
  line-height: 1;
  color: white;
  opacity: .9;
}

.PageHeader_Info {
  color: white;
  opacity: .5;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }