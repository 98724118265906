@import '../../scss/colors';
@import '../../scss/const';

.SideNavigation {
  background: white;
  box-shadow: 0 -1px 12px $shadow-light, 0 1px 4px 0 rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 140px;
  height: 100%;
  display: grid;
  align-content: center;
  transition: .4s;
  text-align: center;
  z-index: 1;
  padding-bottom: env(safe-area-inset-bottom);

  ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: 0 15px;
    padding: 0;
    flex-direction: column;

    li {
      flex: 1;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  a {
    color: $dark-gray;
    text-decoration: none;
    display: block;
    font-family: $font-martin;
    text-transform: uppercase;
    padding: 6px 10px;
    border-radius: 8px;

    &.active, &.active:hover {
      background: $primary;
      color: white;
    }

    &:hover {
      background: $primary-light;
      color: $primary;
    }

    span {
      display: block;
      line-height: 1.2;
    }

    span.NavIcon {
      margin-bottom: 4px;
      height: 22.5px;
    }

    span.NavTitle {
      font-size: 20px;
      line-height: 1;
    }
  }

  .Logo {
    position: absolute;
    top: 15px;
    width: 100%;

    svg {
      width: 60px;
    }
  }
}

.Desktop {
  .PageWrapper, .DateNavigation.TopDateNavigation, .PageHeader .PageHeader_Bar {
    margin-left: 140px;
  }

  .DateNavigation.TopDateNavigation {
    width: calc(100% - 140px);
  }

  .SchedulePage.hasDateNavigation {
    height: calc(100vh - 75px);
    margin-bottom: 0;
  }

  main {
    margin-bottom: 0;
  }
}