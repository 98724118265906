@import '../../scss/colors';
@import '../../scss/const';

.HtmlContent {
}

.InlineCard {
  margin: 20px 0;

  h2 {
    font-family: $font-martin;
    font-weight: 400;
    font-size: 26px;
    text-transform: uppercase;
    letter-spacing: normal;
    line-height: .9;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
  }
}

.InlineBanner {
  margin: 20px 0;
}

.InlineSchedule {
  h2 {
    font-family: $font-martin;
    font-weight: 400;
    font-size: 26px;
    text-transform: uppercase;
    letter-spacing: normal;
    line-height: .9;
    margin-bottom: 0;
  }

  h3 {
    color: $dark-gray;
    text-decoration: none;
    display: block;
    font-family: $font-martin;
    text-transform: uppercase;
    font-size: 22px;
    margin-bottom: 5px;
  }

  .swiper {
    display: flex;
    flex-direction: column-reverse;
  }
}