@import '../../scss/colors';
@import '../../scss/const';

.Calendar {
  .Toolbar {
    margin-bottom: 10px;
  }

  .rbc-time-view .rbc-row {
    min-height: 0px;
  }

  .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
    background: #f4f4f4;
  }

  .rbc-time-header {
    & > .rbc-row:nth-of-type(2) {
      display: none !important;
    }

    .rbc-header, .rbc-row-resource {
      border-bottom: 0;
      overflow: visible;
    }
  }

  .rbc-time-view, .rbc-agenda-view {
    border-radius: 6px;
    border: 1px solid #ddd;

    .rbc-agenda-table {
      border: none;

      .rbc-agenda-date-cell {
        border-right: 1px solid #ddd;
      }
    }
  }

  .rbc-time-view {
    overflow: hidden;
  }

  .rbc-event {
    background: none;
    padding: 0;
    border: none!important;
    //background: white;
    //color: black;
    //box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px, rgba(21, 21, 21, 0.08) 0px 2px 8px -2px, rgba(21, 21, 21, 0.08) 0px 6px 12px -2px;
    //border: none !important;
    //padding: 8px;

    //&:hover {
    //  height: auto !important;
    //}
    border-radius: 0;
    overflow: initial;
  }

  .Event {
    min-height: 100%;
    width: 100%;
    background: white;
    color: black;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px, rgba(21, 21, 21, 0.08) 0px 2px 8px -2px, rgba(21, 21, 21, 0.08) 0px 6px 12px -2px;
    border: none !important;
    padding: 8px 8px 8px 12px;
    border-radius: 6px;
    overflow: hidden;

    &:hover {
      position: absolute;
      height: auto;
      z-index: 1;
    }

    .Event_Name {
      font-size: 14px;
      line-height: 1.1;
    }

    .Event_Bar {
      position: absolute;
      height: 100%;
      top: 0;
      width: 2px;
      left: 4px;

      div {
        height: calc(100% - 12px);
        margin-top: 6px;
        border-radius: 2px;
      }
    }

    .Event_Tag {
      font-size: 9px;
      display: inline-block;
      margin-right: auto;
      padding: 0 5px;
      text-transform: uppercase;
      font-weight: bold;
      border-radius: 2px;
      margin-bottom: 5px;
    }

    .Event_Time {
      color: $gray;
      font-size: 11px;
    }

    .Event_Location {
      font-size: 11px;
    }
  }
}